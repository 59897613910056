import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ApolloModule } from 'apollo-angular';
import { filter, first, switchMap } from 'rxjs';
import { KindeAuthService } from '../auth/kinde/kinde-auth.service';
import { OrganizationService } from './services/organization.service';
import { PropertyUnitService } from './services/property-unit.service';
import { PropertyService } from './services/property.service';
import { RcApiInterceptor } from './services/rc-api.interceptor';
import { RcApiService } from './services/rc-api.service';
import { TaskScheduleService } from './services/task-schedule.service';
import { TaskService } from './services/task.service';
import { UserService } from './services/user.service';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RcApiInterceptor,
      multi: true,
    },
    {
      // Hook up AuthService -> UserService to push user profile into RC API as soon as user profile data is available
      provide: APP_INITIALIZER,
      multi: true,
      useFactory:
        (authService: KindeAuthService, userService: UserService) =>
        (): void => {
          authService.user$
            .pipe(
              filter((user) => user !== null),
              first(),
              switchMap((user) => {
                return userService.updateProfile({
                  name: `${user!.given_name} ${user!.family_name}`,
                });
              }),
            )
            .subscribe();
        },
      deps: [KindeAuthService, UserService],
    },
    RcApiService,
    OrganizationService,
    PropertyService,
    PropertyUnitService,
    TaskService,
    TaskScheduleService,
    UserService,
  ],
  declarations: [],
  imports: [CommonModule, ApolloModule],
})
export class RcApiModule {}
