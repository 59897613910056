import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class RCTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  public override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState);
    const prefix = environment.production ? 'Rent Chores' : '(DEV) Rent Chores';
    if (title !== undefined) {
      this.title.setTitle(`${prefix} | ${title}`);
    } else {
      this.title.setTitle(prefix);
    }
  }
}
