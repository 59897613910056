import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  provideRouter,
  TitleStrategy,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
} from '@angular/router';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { withScalars } from 'apollo-link-scalars';
import { buildClientSchema } from 'graphql';
import { DateTimeResolver } from 'graphql-scalars';
import { NgxStripeModule } from 'ngx-stripe';
import { MessageService } from 'primeng/api';
import { appRoutes } from './app/app.routes';
import { environment } from './app/environments/environment';
import { RcAuthModule } from './app/modules/auth/auth.module';
import { RcCmsModule } from './app/modules/cms/cms.module';
import { RC_CMS_APOLLO_CLIENT_NAME } from './app/modules/cms/types';
import { introspectionResult } from './app/modules/rc-api/generated';
import { RcApiModule } from './app/modules/rc-api/rc-api.module';
import { RC_API_APOLLO_CLIENT_NAME } from './app/modules/rc-api/services/rc-api.service';
import { RcUiKitModule } from './app/modules/rc-ui-kit/rc-ui-kit.module';
import { RcTrackingModule } from './app/modules/tracking/tracking.module';
import { RcAppComponent } from './app/pages/app.component';
import { RCTitleStrategy } from './app/services/title-strategy.service';

bootstrapApplication(RcAppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      CommonModule,
      FormsModule,
      NgxStripeModule.forRoot(environment.stripe.publishableKey),
      RcApiModule,
      RcAuthModule,
      RcCmsModule,
      RcTrackingModule,
      RcUiKitModule,
      ReactiveFormsModule,
    ),
    MessageService,
    // Must provide various apollo configs here vs. their more ideal local-module scope due to angular apollo setup
    // Maybe refactor into a general GraphqlModule that provides the individual clients to their respective modules?
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(httpLink: HttpLink): NamedOptions {
        // Map GraphQL scalars to appropriate resolvers here
        const typesMap = {
          DateTime: DateTimeResolver,
        };
        const schema = buildClientSchema(introspectionResult);

        // Used to automatically transform scalars into interal data types based on typesMap above
        const buildLink = (
          opts: Parameters<typeof httpLink.create>[0],
        ): ApolloLink =>
          ApolloLink.from([
            withScalars({ schema, typesMap }),
            httpLink.create(opts),
          ]);

        return {
          [RC_CMS_APOLLO_CLIENT_NAME]: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: environment.cms.graphqlUrl,
            }),
          },
          [RC_API_APOLLO_CLIENT_NAME]: {
            cache: new InMemoryCache(),
            link: buildLink({
              uri: `${environment.apiUrl}/graphql`,
            }),
            defaultOptions: {
              query: {
                //fetchPolicy: 'network-only',
              },
              watchQuery: {
                refetchWritePolicy: 'overwrite',
              },
              mutate: {
                // Workaround for defaulting all mutations to clear query caching
                update: async (cache): Promise<void> => {
                  cache.evict({ id: 'ROOT_QUERY' });
                  cache.gc();
                },
                awaitRefetchQueries: true,
              },
            },
          },
        };
      },
      deps: [HttpLink],
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideRouter(
      appRoutes,
      withEnabledBlockingInitialNavigation(),
      // Doesn't appear to be working well for standalone components?
      //withPreloading(QuicklinkStrategy),
      withPreloading(PreloadAllModules),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
      withComponentInputBinding(),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      }),
    ),
    { provide: TitleStrategy, useClass: RCTitleStrategy },
  ],
}).catch((err) => console.error(err));
